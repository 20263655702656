<template>
  <main class="main  page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="left">
                  <div class="select-container" v-if="sortOptions.length">
                    <div class="desc">Sort:</div>
                    <Select2 class="select2 single" v-model="sort"
                             :options="sortOptions"
                             placeholder="Highest price first"
                             @select="selectSort($event)"
                    />
                  </div>
                  <div class="select-container" v-if="newCategoryOptions.length">
                    <Select2 class="select2 single" v-model="category"
                             :options="newCategoryOptions"
                             placeholder="All types"
                             @select="selectCategory($event)"
                    />
                  </div>
                  <!-- <div class="select-container" v-if="qualityOptions.length">
                    <div class="desc">Quality:</div>
                    <Select2 class="select2 single" v-model="quality" 
                    :options="qualityOptions" 
                    placeholder="All quality"
                    @select="selectQuality($event)"
                    />
                  </div> -->
                  <div class="select-container select-container-range">
                    <div class="desc">Price:</div>
                    <div class="val-container">
                      <div class="val min">
                        <input type="number" v-model="barMinValue"/>
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                      <div class="divider">-</div>
                      <div class="val max">
                        <input type="number" v-model="barMaxValue"/>
                        <div class="desc">{{ currencySymbol }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <div class="reset" @click="resetFilters">
                    <div class="desc">Reset</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true"
                             @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
              </div>
            </div>
          </transition>
          <pagination v-if="totalProducts" v-model="activePage" :records="totalProducts" :per-page="18"
                      @paginate="changePage"/>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import Pagination from 'vue-pagination-2';
import ProductCard from '../components/ProductCard.vue';
import {mapGetters} from "vuex";

export default {
  name: 'ProductListPage',
  props: ['addToCartChosenItem', 'currency', 'productCategory'],
  components: {
    Select2,
    Pagination,
    ProductCard
  },
  data: function () {
    return {
      imgDomain: '',
      filtersIsVisible: false,
      sort: 'desc',
      category: '',
      quality: '',
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        },
        {
          id: 'asc',
          text: 'Lowest price first'
        }
      ],
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 1200,
      productList: {}
    }
  },
  watch: {
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    productCategory: function () {
      this.activePage = 1;
      this.filterProducts();
    }
  },
  computed: {
    newCategoryOptions() {
      let newCategories = [...this.$parent.categoryOptions];
      newCategories.unshift('All types');
      return newCategories;
    },
    ...mapGetters('app', ['currencySymbol', 'currency'])
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    if (this.productCategory === 'all') {
      this.$parent.setProductCategory('');
    }
    this.$http.get(process.env.VUE_APP_API + 'items/list?page=1&sort=desc&limit=18&type=' + this.productCategory + '&currency=' + this.currency)
        .then((res) => {
          this.productList = res.data.payload
          this.totalProducts = res.data.meta.total
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        })

    this.$parent.getCategoryOptions();
    this.getQualityOptions();

  },
  methods: {
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.$parent.setProductCategory(event.text);
    },
    selectQuality(event) {
      this.activePage = 1;
      this.quality = event.text;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = 'All quality';
      this.category = 'All types';
      this.qualityOptions = '';
      this.$parent.getCategoryOptions();
      this.getQualityOptions();
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.filterProducts();
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let addCategory = '';
      if (this.category != 'All types') {
        addCategory = 'type=' + this.productCategory
      } else {
        addCategory = '';
        addCategory = this.productCategory;
      }

      let addQuality = '';
      if (this.quality != 'All quality') {
        addQuality = '&quality=' + this.quality
      } else {
        addQuality = '';
      }
      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage

      let addCurrency = '&currency=' + this.currency

      this.$http.get(process.env.VUE_APP_API + 'items/list?' + addCategory + addQuality + addPrice + addSort + addPage + addCurrency + '&limit=18')
          .then((res) => {
            console.log(res)
            this.productList = res.data.payload
            this.totalProducts = res.data.meta.total
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignIn();
            }
          })
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },

    getQualityOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
          .then((res) => {
            let newQuality = res.data.payload.qualities;
            newQuality.unshift('All quality');
            this.qualityOptions = newQuality;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignIn();
            }
          })
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>